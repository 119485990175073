import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['value', 'total']

  static values = {
    thresold: Object,
    precision: Number
  }

  connect () {
    this.updateTotal()
  }

  updateTotal () {
    const total = this.filterInputs(this.valueTargets)
      .map(input => parseFloat(input.value))
      .filter(Boolean)
      .reduce((a, b) => a + b, 0)

    this.totalTarget.value = total.toFixed(this.precisionValue)

    this.stylizeTotal(total)
  }

  filterInputs (inputs) {
    return inputs.filter(input => {
      const resourceName = input.name.match(/\[\w*\]$/).pop()
      const destroyInputName = input.name.replace(resourceName, '[_destroy]')
      const destroyInput = document.querySelector(`input[name="${destroyInputName}"]`)
      return destroyInput.value !== "1" && destroyInput.value !== "true"
    })
  }

  stylizeTotal (total) {
    switch (this.thresoldType()) {
      case 'max':
        this.totalTarget.classList.toggle('text-danger', total > this.thresold())
        break
      case 'min':
        this.totalTarget.classList.toggle('text-danger', total < this.thresold())
        break
      case 'exact':
        this.totalTarget.classList.toggle('text-danger', total !== this.thresold())
        break
    }
  }

  thresold () {
    return this.thresoldValue.value || 100
  }

  thresoldType () {
    const thresoldType = this.thresoldValue.type

    return ['min', 'max', 'exact'].includes(thresoldType) ? thresoldType : 'max'
  }
}
