import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  static values = {
    confirm: String
  }

  remove (event) {
    const confirmMessage = this.confirmValue

    if (confirmMessage && confirm(confirmMessage) === false) {
      event.preventDefault()
      return
    }

    super.remove(event)
  }
}
