import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modalBody']

  connect () {
    $(this.modalBodyTarget.closest('.modal')).on('hide.bs.modal', () => {
      location.reload()
    })
  }
}
