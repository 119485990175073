import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'content', 'errors']

  open (event) {
    const [, , xhr] = event.detail
    this.contentTarget.innerHTML = xhr.response
    this.setupDatepicker()
    $(this.modalTarget).modal()
  }

  success (event) {
    $(this.modalTarget).modal('hide')

    const [, , xhr] = event.detail
    const location = xhr.getResponseHeader('location')

    if (location) {
      window.location.href = location
    } else {
      window.location.reload()
    }
  }

  error (event) {
    const [, , xhr] = event.detail
    this.errorsTarget.innerHTML = xhr.response
  }

  setupDatepicker () {
    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      language: 'fr',
      autoclose: true,
      todayHighlight: true
    })
  }
}
