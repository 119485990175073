import Remote from "stimulus-remote-rails"

export default class extends Remote {
  update (event) {
    const [,, xhr] = event.detail
    const response = JSON.parse(xhr.response)

    $(event.target.closest('.modal')).modal('hide')

    $(document).trigger('appel_de_fonds_echeance_controller.payment-changed', response)
  }
}
