export function roundTo (value, roundTo) {
  const roundUp = (value % roundTo) >= (roundTo / 2)
  const roundingFunction = roundUp ? 'ceil' : 'floor'

  return Math[roundingFunction](value / roundTo) * roundTo
}

export function formatNumber (value, unit, precision = 0) {
  const string = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  }).format(value)

  // TODO refactor, use 'style' option for Intl.NumberFormat
  return [string, unit].filter(Boolean).join(' ')
}

export function formatPrice (value, currency = '€', precision = 0) {
  if (!value) return

  return formatNumber(parseFloat(value), currency, precision)
}

export function vatAmount (amountWithoutTaxes, tax) {
  const vatAmount = (parseFloat(amountWithoutTaxes) * parseFloat(tax) / 100) || 0

  return parseFloat(vatAmount.toFixed(2))
}

export function amountWithTaxes (amountWithoutTaxes, tax) {
  const amount = parseFloat(amountWithoutTaxes) || 0
  return amount + vatAmount(amount, tax)
}

export function amountWithoutTaxes (amountWithTaxes, tax) {
  return (parseFloat(amountWithTaxes) / (1 + (parseFloat(tax) / 100))) || 0
}
