import { Controller } from 'stimulus'
import { amountWithTaxes } from "~/utils/number-helpers"

export default class extends Controller {
  static targets = ['amountWithoutTaxes', 'vatAmount', 'amountWithTaxes']

  updateAmountWithVat () {
    this.amountWithTaxesTarget.value = amountWithTaxes(this.amountWithoutTaxesTarget.value, this.vatAmountTarget.value)

    this.amountWithTaxesTarget.dispatchEvent(new Event('input'))
  }
}
