import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit', 'checkbox', 'checkboxAll', 'paymentDate', 'paymentDropdown']

  connect () {
    this.toggleSubmit()

    this.checkboxAllTargets.forEach(c => this.updateCheckboxAll(c))

    $(document).on('appel_de_fonds_echeance_controller.payment-changed', function (_event, payload) {
      this.updateAppelCheckbox(payload)
      this.updatePaymentDate(payload)
      this.updatePaymentDropdown(payload)
    }.bind(this))
  }

  toggleSubmit () {
    if (this.checkedCheckboxes.length > 0) {
      this.submitTarget.innerText = `Envoyer appels de fonds (${this.checkedCheckboxes.length})`
    } else {
      this.submitTarget.innerText = `Récapitulatif général`
    }
  }

  updateCheckboxAll (target_or_event) {
    const target = target_or_event.target ? target_or_event.target : target_or_event
    const echeanceId = target.getAttribute('data-echeance-id')
    const checkboxesAll = this.checkboxAllTargets.filter(c => c.getAttribute('data-echeance-id') === echeanceId)
    const checkboxes = this.checkboxTargets.filter(c => !$(c).prop('disabled') && c.getAttribute('data-echeance-id') === echeanceId)
    const checked = checkboxes.length && checkboxes.every(c => c.checked)

    checkboxesAll.forEach(c => { c.checked = checked })
  }

  updateCheckbox (event) {
    const echeanceId = event.target.getAttribute('data-echeance-id')
    const checkboxesAll = this.checkboxAllTargets.filter(c => c.getAttribute('data-echeance-id') === echeanceId)
    const checkboxes = this.checkboxTargets.filter(c => !$(c).prop('disabled') && c.getAttribute('data-echeance-id') === echeanceId)

    checkboxesAll.forEach(c => { c.checked = event.target.checked })
    checkboxes.forEach(c => { c.checked = event.target.checked })

    this.toggleSubmit()
  }

  get checkedCheckboxes () {
    if (!this.hasCheckboxTarget) return

    return this.checkboxTargets.filter(c => !$(c).prop('disabled') && c.checked)
  }

  updateAppelCheckbox (payload) {
    const checkbox = this.checkboxTargets.find(c => {
      return c.getAttribute('data-appel-id') === payload.appel_id.toString()
    })

    if (!checkbox) return

    if (payload.paid) checkbox.checked = false
    $(checkbox).prop('disabled', payload.paid)
  }

  updatePaymentDate (payload) {
    const paymentDateElement = this.paymentDateTargets.find(p => {
      return p.getAttribute('data-appel-id') === payload.appel_id.toString()
    })

    if (!paymentDateElement) return

    paymentDateElement.innerHTML = payload.payment_date_content
  }

  updatePaymentDropdown (payload) {
    const paymentDropdownElement = this.paymentDropdownTargets.find(c => {
      return c.getAttribute('data-appel-id') === payload.appel_id.toString()
    })

    if (!paymentDropdownElement) return

    paymentDropdownElement.innerHTML = payload.payment_dropdown_content
  }
}
