import { Controller } from 'stimulus'
import { formatPrice } from '~/utils/number-helpers'

export default class extends Controller {
  static targets = [
    'amountWithoutTaxes',
    'amountWithTaxes',
    'totalAmountWithoutTaxes',
    'totalAmountWithTaxes',
    'totalAmountToCollectWithoutTaxes',
    'totalAmountToCollectWithTaxes'
  ]

  connect () {
    this.updateTotals()
  }

  updateTotals () {
    this.totalAmountWithoutTaxesTarget.value = formatPrice(this.amountWithoutTaxesValue, '', 2)
    this.totalAmountWithTaxesTarget.value = formatPrice(this.amountWithTaxesValue, '', 2)
    this.totalAmountToCollectWithoutTaxesTarget.value = formatPrice(this.amountToCollectWithoutTaxesValue, '', 2)
    this.totalAmountToCollectWithTaxesTarget.value = formatPrice(this.amountToCollectWithTaxesValue, '', 2)
  }

  get amountWithoutTaxesValue () {
    return this.sum(this.amountWithoutTaxesTargets)
  }

  get amountWithTaxesValue () {
    return this.sum(this.amountWithTaxesTargets)
  }

  get amountToCollectWithoutTaxesValue () {
    return this.sum(this.amountNotOfferedWithoutTaxes)
  }

  get amountToCollectWithTaxesValue () {
    return this.sum(this.amountNotOfferedWithTaxes)
  }

  get amountNotOfferedWithoutTaxes () {
    return this.notOfferedLines(this.amountWithoutTaxesTargets)
  }

  get amountNotOfferedWithTaxes () {
    return this.notOfferedLines(this.amountWithTaxesTargets)
  }

  notOfferedLines (elements = []) {
    return elements.filter((element) => {
      return !element.parentElement.parentElement.parentElement.querySelector("[data-target='amountOffered']").checked
    })
  }

  sum (elements = []) {
    return this.visibleElements(elements).reduce((accumulator, element) => accumulator + parseFloat(element.value) || 0, 0)
  }

  visibleElements (elements = []) {
    return elements.filter(element => element.offsetParent !== null)
  }
}
