import Carousel from "stimulus-carousel"

export default class extends Carousel {
  get defaultOptions () {
    return {
      slidesPerView: 4.2,
      spaceBetween: 10,
      grabCursor: true
    }
  }
}
