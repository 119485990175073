import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'child',
    'parent'
  ]

  initialize () {
    this.parentTargets.forEach(parent => {
      const userId = parent.dataset.userId

      parent.checked = this.childrenFor(userId).every(c => c.checked)
    })
  }

  updateChildren (e) {
    const userId = e.target.dataset.userId

    this.childrenFor(userId).forEach(child => {
      child.checked = e.target.checked
    })
  }

  updateParent (e) {
    const userId = e.target.dataset.userId
    const parent = this.parentFor(userId)

    parent.checked = this.childrenFor(userId).every(c => c.checked)
  }

  parentFor (userId) {
    return this.parentTargets.find(p => {
      return p.dataset.userId === userId
    })
  }

  childrenFor (userId) {
    return this.childTargets.filter(c => {
      return c.dataset.userId === userId
    })
  }
}
