import { Application } from 'stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Reveal from 'stimulus-reveal-controller'
import Remote from 'stimulus-remote-rails'
import Carousel from "stimulus-carousel"
import NestedForm from "stimulus-rails-nested-form"

const stimulus = Application.start()
registerControllers(stimulus, import.meta.globEager('./*_controller.js'))
stimulus.register('checkbox-select-all', CheckboxSelectAll)
stimulus.register('reveal', Reveal)
stimulus.register('remote', Remote)
stimulus.register("carousel", Carousel)
stimulus.register("nested-form", NestedForm)
