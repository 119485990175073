import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["iframe", "button", "background"]

  play () {
    this.iframeTarget.contentWindow.postMessage('{"event":"command", "func":"' + 'playVideo' + '", "args":""}', '*')
    setTimeout(() => {
      this.backgroundTarget.style.display = "none"
      this.buttonTarget.style.display = "none"
    }, 250)
  }
}
