import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    content: String,
    disableWith: String
  }

  connect () {
    this.originalInnerHTML = this.element.innerHTML
    super.connect()
  }

  copy (event) {
    event.preventDefault()
    event.stopPropagation()

    navigator.clipboard.writeText(this.contentValue)

    this.disableElement()
    setTimeout(this.enableElement.bind(this), 1000)
  }

  enableElement () {
    this.element.classList.remove("disabled") // works for both <a> and <button>
    this.element.innerHTML = this.originalInnerHTML
  }

  disableElement () {
    this.element.classList.add("disabled") // works for both <a> and <button>
    this.element.innerHTML = this.disableWithValue
  }
}
