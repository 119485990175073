import { Controller } from 'stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
  static targets = ['input', 'hiddenField']

  connect () {
    if (!this.hasInputTarget) return

    new Cleave(this.inputTarget, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      delimiter: ' ',
      onValueChanged: e => this.updateHiddenFieldValue(e.target.rawValue)
    })
  }

  updateHiddenFieldValue (value) {
    if (!this.hasHiddenFieldTarget) return

    this.hiddenFieldTarget.value = value
  }
}
