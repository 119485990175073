import Remote from "stimulus-remote-rails"

export default class extends Remote {
  replace (event) {
    super.replace(event)

    const [, , xhr] = event.detail

    // QUICKFIX ugly as fuck but I couldn't find a cleaner solution that is not overly complicated :/
    const modalBtn = window.latestClickedCommentsBtn

    if (!modalBtn) return

    const commentsCount = parseInt(xhr.getResponseHeader('Comments-Count'))

    if (commentsCount === 0) {
      modalBtn.classList.add("text-secondary")
      modalBtn.classList.remove("text-danger")
    } else {
      modalBtn.classList.remove("text-secondary")
      modalBtn.classList.add("text-danger")
    }
  }
}
